<template>
  <div v-if="navigationList.length > 1" class="left-nav">
    <ul>
      <!-- use the router-link component for navigation. -->
      <!-- specify the link by passing the `to` prop. -->
      <!-- `<router-link>` will render an `<a>` tag with the correct `href` attribute -->

      <li :key="route.name" v-for="route in navigationList">
        <router-link :title="$t(route.meta.title)" :to="route.path">{{
          $t(route.meta.title)
        }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftNavigation",
  computed: {
    ...mapGetters({
      session: "session",
      hasCrew: "user/crew/hasCrew",
      pool_roles: "user/roles/pool",
      hasPoolPermission: "user/roles/hasPoolPermission",
      currentUser: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    navigationList() {
      return this.routeChildren.filter((route) => {
        // Check for correct meta
        if (route.meta == undefined) {
          route.meta = { title: "Missing identifier" };
          return true;
        }

        // Check visibility
        if (route.meta.visible != undefined && !route.meta.visible) {
          return false;
        }

        // Check crew
        if (route.meta.crew != undefined && route.meta.crew) {
          if (!this.session) {
            return false;
          }
          if (!this.hasCrew) {
            return false;
          }
        }

        // Check permissions
        if (route.meta.permissions != undefined) {
          if (!this.session) {
            return false;
          }

          if (this.hasSystemPermission()) {
            var sysroles = this.currentUser.system_roles.find(
              (el) =>
                el.name == "admin" || route.meta.permissions.includes(el.name)
            );
            if (sysroles != undefined) {
              return true;
            }
          }
          if (this.hasPoolPermission()) {
            var roles = this.pool_roles.find((el) =>
              route.meta.permissions.includes(el.name)
            );
            if (roles != undefined) {
              return true;
            }
          }
          return false;
        }

        // Check session
        if (route.meta.session != undefined) {
          if (
            (this.session && route.meta.session == false) ||
            (!this.session && route.meta.session == true)
          ) {
            return false;
          }
        }
        // Check crew
        if (route.meta.crew != undefined) {
          if (!this.session) {
            return false;
          }
          if (!this.user.crew.id) {
            return false;
          }
        }

        return true;
      });
    },
    routeChildren() {
      // TODO: Handle 404 if route or children are not found
      let route = this.$router.options.routes.find(
        (el) => el.name == this.$route.matched[0].name
      );

      if (!route) {
        return null;
      }
      return route.children;
    },
  },
};
</script>
<style lang="scss" scoped>
.left-nav {
  @include media(small) {
    display: none;
  }
}
.left-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 250px;
  border-right: solid thin #008fc3;
  height: 100%;
}
.left-nav li {
  float: none;
  text-transform: uppercase;
}
.left-nav li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

.left-nav li a.router-link-exact-active {
  background-color: #002e6d;
  color: white;
}

.left-nav li a:hover:not(.router-link-exact-active) {
  background-color: #008fc3;
  color: white;
}
</style>
